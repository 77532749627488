import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({ data }) => {
  const socialCards = data.social_cards || [];

  const generalCard = socialCards
    .filter(item => item.type === 'general_card')
    .slice(0, 1)
    .pop();

  const twitterCard = socialCards
    .filter(item => item.type === 'twitter_card')
    .slice(0, 1)
    .pop();

  const twitterCardType = get(twitterCard, 'primary.card_type');

  let twitterImage = get(twitterCard, 'primary.image.url');
  if (twitterCardType === 'summary_large_image') {
    // Get large image if exists, otherwise use standard image
    twitterImage = get(
      twitterCard,
      'primary.image.large_image.url',
      twitterImage
    );
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: data.lang || 'en',
      }}
      title={data.title}
      meta={[
        {
          name: `description`,
          content: data.description,
        },
        {
          name: `keywords`,
          content: data.keywords,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: get(generalCard, 'primary.title'),
        },
        {
          property: `og:description`,
          content: get(generalCard, 'primary.description'),
        },
        {
          property: `og:image`,
          content: get(generalCard, 'primary.image.url'),
        },
        {
          property: `og:url`,
          content: get(generalCard, 'primary.url'),
        },
        {
          name: `twitter:card`,
          content: twitterCardType,
        },
        {
          name: `twitter:site`,
          content: get(twitterCard, 'primary.site'),
        },
        {
          name: `twitter:title`,
          content: get(twitterCard, 'primary.title'),
        },
        {
          name: `twitter:description`,
          content: get(twitterCard, 'primary.description'),
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
      ]}
    />
  );
};

SEO.propTypes = {
  data: PropTypes.object,
};

SEO.defaultProps = {
  data: {},
};

export default SEO;
